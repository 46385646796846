import '@babel/polyfill';

import {SETTINGS} from "./checkout/Settings";
import MultiSafepayCheckout from "./checkout/MultiSafepayCheckout";
import DatePicker from "./checkout/components/DatePicker";
import Input from "./checkout/components/Input";
import Dropdown from "./checkout/components/Dropdown";
import Checkbox from "./checkout/components/Checkbox";
import RadioButtons from "./checkout/components/RadioButtons";
import Section from "./checkout/Section";
import Payment from "./checkout/components/Payment";

import MultiSafepayExtenstionDMWS from "./checkout/MultiSafepayExtenstionDMWS";


const CanUseApplePay = () => {
    let applePayOption = document.querySelector(`label[for="gui-payment-${SETTINGS.nameDashed}-APPLEPAY"]`);
    let applePayParentDiv = applePayOption.closest('div.gui-payment-method.gui-payment-method-service');
    if (applePayOption) {
        try {
            if (window.ApplePaySession && ApplePaySession.canMakePayments()) {
                applePayOption.style.display = "block";
                applePayParentDiv.style.display = "block";
            } else {
                applePayOption.style.removeProperty('display');
                applePayOption.style.display = "none";
                applePayParentDiv.style.removeProperty('display');
                applePayParentDiv.style.display = "none";
            }
        } catch (error) {
            console.debug('An error occurred while verifying if Apple Pay is available:', error);
            applePayOption.style.removeProperty('display');
            applePayOption.style.display = "none";
            applePayParentDiv.style.removeProperty('display');
            applePayParentDiv.style.display = "none";
        }
    }
};

const CanUsePADB2B = () => {
    let padOption = document.querySelector(`label[for="gui-payment-${SETTINGS.nameDashed}-PAYAFTB2B"]`);
    if (padOption) {
        padOption.style.display = "none";
    }
};

let createCheckout = async event => {
    if (!event || event.target.readyState === "complete") {
        if (document.getElementsByClassName(`gui-payment-provider-${SETTINGS.nameDashed}`).length <= 0 || !(window.Checkout.data || window.Checkout)) {
            CanUsePADB2B();
            return;
        }

        if (!window[SETTINGS.nameDashed]) {
            window[SETTINGS.nameDashed] = {}
        }

        let mspCheckout = undefined
        if (Checkout.data.theme === "default") {
            mspCheckout = new MultiSafepayCheckout(".gui-right .gui-button-small.gui-button-action", ".gui-col2-right", ".gui-checkout-steps");
        } else if (Checkout.data.theme === "onepage") {
            mspCheckout = new MultiSafepayCheckout(".gui-button-large.gui-button-action", ".gui-form", "#gui-form");
        } else if (Checkout.data.theme === "onestep") {
            mspCheckout = new MultiSafepayCheckout(".gui-confirm-buttons .gui-button-action", ".gui-form", "#gui-form");
        }

        if (mspCheckout) {
            const methods = Checkout.data.payment_methods.data[SETTINGS.nameDashed].methods;
            let currentButton = undefined;
            let currentTermsCheck = undefined;
            let currentTermsLabel = undefined;
            let currentNewsletterCheck = undefined;
            let currentNewsletterLabel = undefined;

            let checkboxEvent = (event) => {
                document.querySelectorAll("input[id$='-check']").forEach(check => check.checked = event.target.checked);
            };

            let newsletterEvent = event => {
                document.querySelectorAll("input[id$='-newslettercheck']").forEach(check => check.checked = event.target.checked);
            };

            if (Checkout.data.theme === "onepage" || Checkout.data.theme === "onestep") {
                currentButton = document.querySelector(mspCheckout.checkoutButton.query);

                currentTermsCheck = document.querySelector("#gui-form-terms");
                currentTermsLabel = document.querySelector('label[for="gui-form-terms"]');
                if (currentTermsCheck) {
                    currentTermsCheck.addEventListener('change', checkboxEvent);
                } else {
                    currentTermsLabel = document.querySelector('.gui-terms-message-only');
                }

                currentNewsletterCheck = document.querySelector('#gui-form-newsletter');
                currentNewsletterLabel = document.querySelector('label[for="gui-form-newsletter"]');
                if (currentNewsletterCheck) {
                    currentNewsletterCheck.addEventListener('change', newsletterEvent);
                }
            }

            if (usesComponents(methods)) {
                await Payment.addScript();
                await Payment.addStyle();
                await new Promise(resolve => setTimeout(resolve, 100));
            }

            for (const key of Object.keys(methods)) {
                let section = new Section(key);
                if (methods[key].data["component"] && methods[key].data["component"]["enabled"]) {
                    section = section.add(new Payment(mspCheckout, key, "", "", methods[key].data["component"]));
                } else if (methods[key].data.fields) {
                    const fields = methods[key].data.fields;
                    for (const field of Object.keys(fields)) {
                        if (fields[field].type === "select") {
                            section = section.add(new Dropdown(mspCheckout, fields[field].id, fields[field].label, fields[field].error, fields[field].values));
                        } else if (fields[field].type === "radio") {
                            section = section.add(new RadioButtons(mspCheckout, fields[field].id, fields[field].label, fields[field].values, fields[field].error));
                        } else if ((fields[field].type === "date")) {
                            section = section.add(new DatePicker(mspCheckout, fields[field].id, fields[field].label, fields[field].error));
                        } else if ((fields[field].type === "input")) {
                            section = section.add(new Input(mspCheckout, fields[field].id, fields[field].label, fields[field].values, fields[field].error));
                        } else if ((fields[field].type === "checkbox")) {
                            section = section.add(new Checkbox(mspCheckout, fields[field].id, fields[field].label, fields[field].values, fields[field].error));
                        }
                    }
                }

                if (section._form.children.length > 0) {
                    mspCheckout.add(key.toUpperCase(), section);
                } else {
                    section._form.remove();
                }
            }
        }

        if ((document.getElementsByClassName(`gui-payment-provider-${SETTINGS.nameDashed}`).length > 0)) {
            CanUseApplePay();
        }
    }
};

const usesComponents = (methods) => {
    for (const key of Object.keys(methods)) {
        if (methods[key].data["component"] && methods[key].data["component"]["enabled"]) {
            return true;
        }
    }

    return false;
}

const oneStepUpdate = async () => {
    if (window.hasOwnProperty("Checkout") && Checkout.data && (Checkout.data.theme === "onestep" || Checkout.data.theme === "onepage")) {
        let oldUpdate = Checkout.update;
        Checkout.update = async params => {
            await oldUpdate(params);
            createCheckout();
            togglePaymentMethods(document.querySelector("#gui-form-billing_address-country").value);
        };
    }
};

const togglePaymentMethods = country => {
    let hide = (key) => {
        let method = document.querySelector(`label[for="gui-payment-${SETTINGS.nameDashed}-${key}"]`);
        if (method) {
            method.style.display = "none";
        }
    };
    let show = (key) => {
        let method = document.querySelector(`label[for="gui-payment-${SETTINGS.nameDashed}-${key}"]`);
        if (method) {
            method.style.display = "block";
        }
    };

    if (!Checkout.data.payment_methods) {
        return;
    }

    // loop
    let countryList = [];
    let checkoutData = Checkout.data.payment_methods.data[SETTINGS.nameDashed];
    if (checkoutData.methods) {
        for (const [key, value] of Object.entries(checkoutData.methods)) {
            if (value.data.countries && value.data.countries.length > 0) {
                countryList.push({
                    "key": key,
                    "countries": value.data.countries
                });
            }
        }
    }

    for (const methods of countryList) {
        if (methods.countries.includes(country)) {
            show(methods.key);
        } else {
            hide(methods.key);
        }
    }

    CanUseApplePay();
};

const createDefaultCheckout = () => {
    window.addEventListener('error', () => {
        createCheckout();
    });
    document.addEventListener('readystatechange', () => {
        createCheckout();
    });
    document.addEventListener('readystatechange', () => {
        oneStepUpdate();
    });

    document.addEventListener('readystatechange', event => {
        if (!event || event.target.readyState === "complete") {
            let billing = document.querySelector("#gui-form-billing_address-country");
            if (billing) {
                document.querySelector("#gui-form-billing_address-country").addEventListener("change", function () {
                    togglePaymentMethods(document.querySelector("#gui-form-billing_address-country").value);
                });
                if (Checkout.data.billing_address.country) {
                    togglePaymentMethods(Checkout.data.billing_address.country);
                }
            }

            if (typeof Checkout !== "undefined" && Checkout.data && Checkout.data.step === "payment") {
                if (Checkout.data.billing_address.country) {
                    togglePaymentMethods(Checkout.data.billing_address.country);
                }
            }
        }
    });
}

const CreateMultiSafepayExtenstionDMWS = () => {
    let msp = new MultiSafepayExtenstionDMWS(SETTINGS.nameDashed);
    msp.format();

    //validation
    const form = document.querySelector('form[id="gui-form"]');
    const paymentbox = form.querySelector('div[class="gui-payment-methods"]');

    if (document.body.contains(form) && document.body.contains(paymentbox)) {
        window.addEventListener('load', function () {
            let payBtn = form.querySelectorAll('button[class="btn"][type="submit"]');
            payBtn.forEach((index) => {
                index.addEventListener('click', function (event) {
                    const theSelected = form.querySelector('.selected');

                    const data = new FormData(form);
                    var formdata = [];
                    var payload = "";
                    var isMSP = false;
                    //Get the data of the form.
                    for (const value of data.entries()) {
                        //We want to know which payment method
                        if (value[0] === 'payment_method') {
                            var splitIt = value[1].split('|');
                            payload = splitIt[1];
                            if (splitIt[0] === "multisafepay-payments"){
                                isMSP = true;
                            }
                        }
                        //Remove the empty creditcard array because we don't need if it's empty
                        if (value[1] !== '' || payload == 'CREDITCARD') {
                            formdata.push(value);
                        }
                    }
                    var dataLength = formdata.length;
                    if (dataLength>= 2) {
                        dataLength--;
                        //When we have second payload we aspect to be filled
                        if (formdata[dataLength][1] === '' && isMSP){
                            event.preventDefault();
                            theSelected.style.border = "2px solid red";
                        }
                    }
                });
            });
        });
    }

}

const ChangeCheckout = () => {
    if (typeof Checkout === "undefined") {
        return;
    }

    let checkoutData = Checkout?.payment_methods?.data[SETTINGS.nameDashed]?.methods
    if (window.dmws?.settings?.checkout && checkoutData) {
        CreateMultiSafepayExtenstionDMWS();
    } else if (Checkout.data.theme === "default" || Checkout.data.theme === "onepage" || Checkout.data.theme === "onestep") {
        createDefaultCheckout();
    }

}
ChangeCheckout();
