import Component from "./Component";
import { SETTINGS } from "../Settings";

export default class Input extends Component {
    constructor(checkout, name, description, values, error, section = undefined) {
        super(checkout, name, description, error, section, 'input');
        this._settings = values;
    }

    validate = () => {
        if (document.getElementById(`${SETTINGS.nameDashed}-${this._section.name}-${this._name}`)) {
            let regex = new RegExp(this._settings.regex)
            let value = document.getElementById(`${SETTINGS.nameDashed}-${this._section.name}-${this._name}`).value;
            if (regex.test(value)) {
                this._checkout.error.remove(`${SETTINGS.nameDashed}-${this._section.name}-${this._name}`);
                return true
            }
        }

        this._checkout.error.add(this._error, `${SETTINGS.nameDashed}-${this._section.name}-${this._name}`);
        return false;
    }

    create = () => {
        if (document.getElementById(`${SETTINGS.nameDashed}-${this._section.name}-${this._name}`)) { return null; }

        let input = document.createElement('input');
        input.id = `${SETTINGS.nameDashed}-${this._section.name}-${this._name}`;
        input.placeholder = this._settings.placeholder;
        input.pattern = this._settings.regex;
        input.type = this._settings.type;
        input.name = `${this._section.name}[${this._name}]`;
        input.style.cssText = `width: 100%; max-width: inherit;`;

        input.oninput = e => {
            if (window[SETTINGS.nameDashed]) {
                window[SETTINGS.nameDashed][`${this._section.name}-${this._name}`] = e.target.value;
            }
        };

        if (window[SETTINGS.nameDashed] && window[SETTINGS.nameDashed][`${this._section.name}-${this._name}`]) {
            input.value = window[SETTINGS.nameDashed][`${this._section.name}-${this._name}`];
        } else if (Checkout.data.payment_method && Checkout.data.payment_method.data[this._name]) {
            input.value = Checkout.data.payment_method.data[this._name];
        }

        this._section.form.appendChild(this.createField(input));
    }
}
