export default class PaymentComponent
{
    #appName;
    #gateway;
    #data;

    constructor(name, gateway, data)
    {
        this.#appName = name;
        this.#gateway = gateway;
        this.#data = data;
    }

    async load()
    {
        await this.addScript();
        await this.addStyle();

        let containerSelector = `#gui-form-payment-method-${this.#appName}-${this.#gateway}-form`;

        let component = new window.MultiSafepay({
            env: this.#data['environment'],
            apiToken: this.#data['token'],
            order: this.getOrderData()
        });

        let input = document.createElement('input');
        input.name = `${this.#gateway}[payload]`;
        input.style.display = 'none';
        document.querySelector(containerSelector).appendChild(input);

        component.init('payment', {
            container: containerSelector,
            gateway: this.#gateway,
            onLoad: state => {},
            onError: state => {
                console.log('onError', state);
            },
            onValidation:  state => {
                if(state['valid']) {
                    input.value = component.getPaymentData()['payload'];
                }
            },
        });

        window[`${this.#appName}-${this.#gateway}`] = component;
    }

    async addScript() {
        await new Promise((resolve, reject) => {
            if (document.getElementById('componentjs')) {
                resolve(true);
                return;
            }
            const script = document.createElement('script');
            script.async = true;
            script.defer = false;
            script.src = 'https://pay.multisafepay.com/sdk/components/v2/components.js';
            script.id = 'componentjs'

            document.head.appendChild(script);
            script.addEventListener('load', () => {
                resolve(true);
            });
        });
    }

    async addStyle() {
        await new Promise((resolve, reject) => {
            if (document.getElementById('componentcss')) {
                resolve(true);
                return;
            }
            const link = document.createElement('link');
            link.rel = 'stylesheet';
            link.type = 'text/css';
            link.href = 'https://pay.multisafepay.com/sdk/components/v2/components.css';
            link.id = 'componentcss';
            document.head.appendChild(link);
            resolve(true);
        });
    }

    getOrderData() {
        let checkoutData = window.Checkout.data;
        if(!checkoutData){
            checkoutData = window.Checkout;
        }
        let locale = checkoutData.billing_address.country;

        return {
            currency: (this.#data['currency'] + "").toUpperCase(),
            amount: Math.round(Checkout.quote.totals.grand_total * 100),
            customer: {
                locale: locale,
                country: checkoutData.billing_address.country
            },
            template : {
                settings: {
                    embed_mode: true
                }
            }
        };
    }
}
