import MultiSafepayCheckoutError from "./MultiSafepayCheckoutError";
import MultiSafepayCheckoutButton from "./MultiSafepayCheckoutButton";
import {SETTINGS} from "./Settings";


export default class MultiSafepayCheckout {
    constructor(checkoutButtonQuery, guiMessageLocationQuery, guiMessageBeforeQuery, enableScrollOnError = true) {
        this._error = new MultiSafepayCheckoutError(guiMessageLocationQuery, guiMessageBeforeQuery, enableScrollOnError);
        this._checkoutButton = new MultiSafepayCheckoutButton(this, checkoutButtonQuery);
        this._sections = {};
    }

    get checkoutButton() {
        return this._checkoutButton;
    }

    get error() {
        return this._error;
    }

    validate = () => {
        let selected = document.querySelector("input[name=payment_method]:checked");
        if (selected && Object.keys(this._sections).includes(selected.value)) {
            return this._sections[selected.value].validate();
        }
        return true;
    }

    add = (name, section) => {
        this._sections[`${SETTINGS.nameDashed}|${name}`] = section;
    }
}
