import {SETTINGS} from "./Settings";

export default class Section {
    constructor(name) {
        this._name = name;
        this._components = [];

        this._form;
        this._container;
        this._create();
    }

    get form() { return this._form; }
    get name() { return this._name; }


    _create = () => {
        this._container = document.getElementById(`gui-payment-${SETTINGS.nameDashed}-${this._name}`).parentElement.parentElement;
        let guiClear = document.createElement("div");
        guiClear.classList.add("gui-clear");
        this._container.appendChild(guiClear);

        this._form = document.createElement("div");
        this._form.classList.add("gui-payment-method-form");
        if (Checkout.data.theme !== "default") {
            this._form.setAttribute("data-subform", "true");
        }
        this._container.appendChild(this._form);
    }

    add = (component) => {
        this._components.push(component);
        component.section = this;
        component.create();
        return this;
    }

    validate = () => {
        if (this._components.length > 0) {
            return !this._components.map(c => c.validate()).includes(false);
        }
        return true;
    }
}