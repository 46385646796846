/*
Setting object is used for dynamic values. If for example the app name is changed,
only name has to be changed in settings to keep the app working
*/

const APP_NAME = process.env.APP_NAME || "MultiSafepay Payments"
const APP_URL = process.env.APP_URL || "https://lightspeed.multisafepay.com"

export const SETTINGS = {
    name: APP_NAME,
    nameLower: APP_NAME.toLowerCase(),
    nameDashed: APP_NAME.toLowerCase().replace(" ", "-"),
    url: APP_URL,
};
