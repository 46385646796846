import PaymentComponent from "./PaymentComponent";

export default class MultiSafepayExtenstionDMWS {
    #providerName;
    #dmwsSettings;

    constructor(providerName) {
        this.#providerName = providerName;
        this.#dmwsSettings = window.dmws.settings.checkout
    }

    async format() {
        this.togglePaymentMethods();
        this.hideApplePay();
        await this.loadCreditCardComponent();
    }

    hideApplePay() {
        let applePayContainer = this.findCheckoutPaymentMethod('APPLEPAY');
        if (!applePayContainer) {
            return;
        }

        try {
            if (window.ApplePaySession && ApplePaySession.canMakePayments()) {
                applePayContainer.style.display = "block";
            } else {
                applePayContainer.style.display = "none";
            }
        } catch (error) {
            console.debug('An error occurred while verifying if Apple Pay is available:', error);
            applePayContainer.style.removeProperty('display');
            applePayContainer.style.display = "none";
        }
    }

    togglePaymentMethods() {
        let paymentMethodsObject = Checkout?.payment_methods?.data[this.#providerName]?.methods;
        if (!paymentMethodsObject) {
            return;
        }

        let paymentMethods = Object.values(paymentMethodsObject);
        let billingCountry = Checkout.billing_address.country;

        for (const paymentMethod of paymentMethods) {
            let countries = paymentMethod.data.countries;
            if (countries.length <= 0) {
                continue;
            }

            if (!countries.includes(billingCountry)) {
                let container = this.findCheckoutPaymentMethod(paymentMethod.id);
                container.style.display = "none";
            }
        }
    }

    async loadCreditCardComponent() {
        let creditcardData = Checkout?.payment_methods?.data[this.#providerName]?.methods["CREDITCARD"]?.data;
        if (!creditcardData) {
            return;
        }

        let creditcardComponentData = creditcardData.component;
        if (!creditcardComponentData || !creditcardComponentData.enabled) {
            return;
        }

        let checkoutCreditcard = this.findCheckoutPaymentMethod("CREDITCARD");
        if (!checkoutCreditcard) {
            return;
        }

        checkoutCreditcard
            .querySelector('.gui-payment-methods')
            .insertAdjacentHTML('afterend', `<div id="gui-form-payment-method-${this.#providerName}-CREDITCARD-form"></div>`);

        await new PaymentComponent(this.#providerName, "CREDITCARD", creditcardComponentData).load();
    }

    findCheckoutPaymentMethod(gateway) {
        if (!document.querySelector(`#gui-payment-${this.#providerName}-${gateway}`)) {
            return null;
        }

        let paymentMethods = document.querySelectorAll('.checkout-payment-method');
        for (const paymentMethod of paymentMethods) {
            if (paymentMethod.querySelector(`#gui-payment-${this.#providerName}-${gateway}`)) {
                return paymentMethod;
            }
        }

        return null;
    }
}
