import Component from "./Component";
import {SETTINGS} from "../Settings";

export default class DatePicker extends Component {
    constructor(checkout, name, description, error, section = undefined) {
        super(checkout, name, description, error, section, 'date');
    }

    validate = () => {
        if (document.getElementById(`${SETTINGS.nameDashed}-${this._section.name}-${this._name}`)) {
            if (document.getElementById(`${SETTINGS.nameDashed}-${this._section.name}-${this._name}`).value !== "") {
                this._checkout.error.remove(`${SETTINGS.nameDashed}-${this._section.name}-${this._name}`);
                return true
            }
        }

        this._checkout.error.add(this._error), `${SETTINGS.nameDashed}-${this._section.name}-${this._name}`;
        return false;
    }

    create = () => {
        if (document.getElementById(`${SETTINGS.nameDashed}-${this._section.name}-${this._name}`)) {
            return null;
        }
        let dateInput = document.createElement('input');
        dateInput.setAttribute('type', 'date');
        dateInput.id = `${SETTINGS.nameDashed}-${this._section.name}-${this._name}`;
        dateInput.name = `${this._section.name}[${this._name}]`;
        dateInput.style.cssText = `border: 1px solid #e5e5e5;
        padding: 6px 10px;
        outline: none;
        font-size: 13px;
        color: #777;
        margin: 0;
        width: 100%; max-width: inherit;
        display: inline-block;
        background: #fff;
        line-height: 18px;
        -moz-transition: border-color 0.3s ease;
        -webkit-transition: border-color 0.3s ease;
        transition: border-color 0.3s ease;`;

        dateInput.onchange = e => {
            if (window[SETTINGS.nameDashed]) {
                window[SETTINGS.nameDashed][`${this._section.name}-${this._name}`] = e.target.value;
            }
        };

        if (window[SETTINGS.nameDashed] && window[SETTINGS.nameDashed][`${this._section.name}-${this._name}`]) {
            dateInput.value = window[SETTINGS.nameDashed][`${this._section.name}-${this._name}`];
        } else if (Checkout.data.payment_method && Checkout.data.payment_method.data[this._name]) {
            dateInput.value = Checkout.data.payment_method.data[this._name];
        }
        this._section.form.appendChild(this.createField(dateInput));
    }
}

