export default class MultiSafepayCheckoutButton {
    constructor(checkout, query) {
        this._query = query;
        this._checkout = checkout;
        this._button = document.querySelector(query);
        if (this._button) {
            this._previousOnclick = this._button.onclick;
            this._button.onclick = this.onclick;
        }
    }

    get query() { return this._query; }

    onclick = () => {
        if (this._checkout.validate()) {
            this._previousOnclick();
        } else {
            return;
        }
    }
}
