import Component from "./Component";
import { SETTINGS } from "../Settings";

export default class Checkbox extends Component {
    constructor(checkout, name, description, values, error, section = undefined) {
        super(checkout, name, description, error, section, 'checkbox');
        this._settings = values;
    }

    validate = () => {
        if (document.getElementById(`${SETTINGS.nameDashed}-${this._section.name}-${this._name}`)) {
            let isChecked = document.getElementById(`${SETTINGS.nameDashed}-${this._section.name}-${this._name}`).checked;
            if (isChecked) {
                this._checkout.error.remove(`${SETTINGS.nameDashed}-${this._section.name}-${this._name}`);
                return true;
            }
        }

        this._checkout.error.add(this._error, `${SETTINGS.nameDashed}-${this._section.name}-${this._name}`);
        return false;
    }

    create = () => {
        if (document.getElementById(`${SETTINGS.nameDashed}-${this._section.name}-${this._name}`)) { return null; }

        let checkbox = document.createElement('input');
        checkbox.id = `${SETTINGS.nameDashed}-${this._section.name}-${this._name}`;
        checkbox.type = 'checkbox';
        checkbox.name = `${this._section.name}[${this._name}]`;
        checkbox.style.cssText = `width: auto;`;

        checkbox.onchange = e => {
            if (window[SETTINGS.nameDashed]) {
                window[SETTINGS.nameDashed][`${this._section.name}-${this._name}`] = e.target.checked;
            }
        };

        if (window[SETTINGS.nameDashed] && window[SETTINGS.nameDashed][`${this._section.name}-${this._name}`]) {
            checkbox.checked = window[SETTINGS.nameDashed][`${this._section.name}-${this._name}`];
        } else if (Checkout.data.payment_method && Checkout.data.payment_method.data[this._name]) {
            checkbox.checked = Checkout.data.payment_method.data[this._name];
        }

        let fieldContainer = document.createElement('div');
        fieldContainer.className = 'gui-field';
        fieldContainer.appendChild(checkbox);

        if (!document.querySelector(`label[for="${checkbox.id}"]`)) {
            let label = document.createElement('label');
            label.htmlFor = checkbox.id;
            label.innerHTML = this._description;
            fieldContainer.appendChild(label);
        }

        this._section.form.appendChild(this.createField(fieldContainer));
    }

}
