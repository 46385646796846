import { SETTINGS } from "./Settings";

export default class MultiSafepayCheckoutError {
    constructor(location, before, scroll) {
        this._location = location;
        this._before = before;
        this._scroll = scroll;
        this._list;
        this._guiMessages;

        this._createList();
    }

    _createList = () => {
        this._guiMessages = document.getElementsByClassName("gui-messages")[0];

        if (!this._guiMessages) {
            this._guiMessages = document.createElement("div");
            this._guiMessages.classList.add("gui-messages");
            this._guiMessages.style.display = "none";
        }
        this._guiMessages = document.querySelector(this._location).insertBefore(this._guiMessages, document.querySelector(this._before));

        this._list = this._guiMessages.querySelector(".gui-error");
        if (!this._list) {
            this._list = document.createElement("ul");
            this._list.classList.add("gui-error");
            this._list.style.display = "none";
        }

        this._guiMessages.appendChild(this._list);
    }

    add = (message, name = "error") => {
        let error = document.createElement("li");
        error.innerHTML = message;
        error.setAttribute(`${SETTINGS.nameDashed}-error-name`, name)
        this._list.appendChild(error);
        if (this._list.childElementCount > 0) {
            this._list.style.display = "block";
            this._guiMessages.style.display = "block";
        }

        if (this._scroll) {
            this._guiMessages.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "end"
            });
        }
    }

    remove = (name) => {
        this._list.querySelectorAll(`[${SETTINGS.nameDashed}-error-name=${name}`).forEach((elem) => elem.parentNode.removeChild(elem));
        if (this._list.childElementCount <= 0) { this._list.style.display = "none"; }
    }
}
